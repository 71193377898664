import GlobalLayout from "components/page/GlobalLayout";
import SensorSpecsPage from "components/sensors/SensorSpecsPage";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function E2() {
  const renderContent = data => {
    const sensor = {
      title: "E2",
      imageHeader: data.e2Header,
      imageHeaderMobile: data.e2HeaderMobile,
      image: data.e2Dims,
      objectPosition: "85%",
      description:
        "Create safer spaces with real-time air quality, smoke, vape, and volatile chemical monitoring. The E2 reveals powerful insights so you can speed up incident responses with real-time alerts.",
      pdf: "/files/specs/E2.pdf",
      column1: [
        {
          title: "Dimensions",
          text: "100mm (4in) x 100mm (4in) x 40mm (1.6in)",
        },
        {
          title: "Range",
          text: "Up to 100m (328.04ft) from a Rhombus Sensor Network base",
        },
        {
          title: "Power",
          text: "Powered by MicroUSB",
        },
        {
          title: "Humidity Measurement",
          text:
            "±3.5% from 20% to 80% relative humidity, ±5% from 0%-20% and 80%-100%",
        },
        {
          title: "Temperature Measurement",
          text:
            "-40°C to 85°C (-40°F to 185°F). Accuracy of ±0.5°C from 15 to 40°C (59 to 104°F) and ±1.0°C outside that range",
        },
      ],
      column2: [
        {
          title: "Detection",
          text:
            "Indoor Air Quality (IAQ), Estimated carbon dioxide levels (eCO2), Total volatile organic compounds (TVOC), Smoke, Vape, Particulate matter",
        },
        {
          title: "Mounting",
          text: "Mounting bracket, screws, and anchors included",
        },
        {
          title: "Outdoor Use",
          text: "For use indoors or outdoors if protected from moisture",
        },
        {
          title: "Accessories",
          text: [
            "Supports external temperature probe via jack (optional); Includes 120V AC Adapter to Micro USB",
          ],
        },
        {
          title: "2-Year Warranty Included",
        },
      ],
    };

    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>
            Rhombus E2 Environmental Sensor - Vape, Smoke, Air Quality
          </title>
          <meta
            name="description"
            content="Enterprise-grade vape, smoke, and air quality sensor for commercial businesses, school districts, healthcare, real estate, and more."
          />
          <meta name="thumbnail" content="https://rhombus.com/img/e2-seo.png" />
        </Helmet>
        <SensorSpecsPage sensor={sensor} />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      e2Header: file(
        relativePath: { eq: "components/sensors/img/e2-header-2000-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      e2HeaderMobile: file(
        relativePath: {
          eq: "components/sensors/img/e2-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      e2Dims: file(
        relativePath: { eq: "components/sensors/img/e2-dims-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
